<template>
  <div class="info">
    <DepositsInfoItem
      title="TODAY"
      :amount="stats?.today?.amountUSD"
      :deposit-qty="stats?.today?.count"
      :is-loading="isLoading"
    />

    <DepositsInfoItem
      title="THIS MONTH"
      :amount="stats?.month?.amountUSD"
      :deposit-qty="stats?.month?.count"
      :is-loading="isLoading"
    />

    <DepositsInfoItem
      title="LAST MONTH"
      :amount="stats?.lastMonth?.amountUSD"
      :deposit-qty="stats?.lastMonth?.count"
      :is-loading="isLoading"
    />

    <DepositsInfoItem
      title="THIS YEAR"
      :amount="stats?.year?.amountUSD"
      :deposit-qty="stats?.year?.count"
      :is-loading="isLoading"
    />

    <DepositsInfoItem
      title="TOTAL"
      :amount="stats?.total?.amountUSD"
      :deposit-qty="stats?.total?.count"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import DepositsInfoItem from './DepositsInfoItem.vue';

export default {
  name: 'DepositsInfo',
  components: {
    DepositsInfoItem,
  },
  props: {
    stats: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.info {
  display: flex;
  gap: 60px;
}
</style>
