<template>
  <TransitionWrapper>
    <TabsContainer
      v-model="currentTab"
      :options="tabs"
      has-colored-titles
      height="50px"
    >
      <div class="sales-tabs">
        <SimpleTransition>
          <div v-if="currentTab === tabs[0].value">
            <DepositsInfo :stats="stats" :is-loading="isStatsLoading" />

            <DepositsTable class="table-wrap" />
          </div>

          <div v-if="currentTab === tabs[1].value">
            <PortfolioInfo :portfolio="portfolio" />

            <InvoicesTable style="margin-top: 40px;" />
          </div>
        </SimpleTransition>
      </div>
    </TabsContainer>
  </TransitionWrapper>
</template>

<script>
import { computed, onBeforeMount, ref } from 'vue';

import SimpleTransition from '@/components/Animation/SimpleTransition.vue';
import TabsContainer from '@/components/Containers/TabsContainer.vue';
import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';

import { plansSortWeight } from '@/common/data';
import { emitter } from '@/composables/useBus';

import { getDepositsData, getPortfolioData } from './api';
import DepositsInfo from './Deposits/DepositsInfo.vue';
import DepositsTable from './Deposits/DepositsTable.vue';
import InvoicesTable from './Portfolio/InvoicesTable.vue';
import PortfolioInfo from './Portfolio/PortfolioInfo.vue';

export default {
  name: 'AdminSales',
  components: {
    TabsContainer,
    SimpleTransition,
    TransitionWrapper,
    DepositsInfo,
    DepositsTable,
    PortfolioInfo,
    InvoicesTable,
  },
  setup() {
    emitter.emit('page-title', 'sidebar.sales');

    const tabs = [
      { text: 'DEPOSITS', value: 'deposits' },
      { text: 'PORTFOLIO', value: 'portfolio' },
    ];

    const isStatsLoading = ref(true);
    const currentTab = ref(tabs[0].value);

    const depositsData = ref(null);
    const stats = computed(() => (depositsData?.value || {}));

    const portfolioData = ref(null);
    const portfolio = computed(() => {
      const res = portfolioData?.value;
      return res?.sort((a, b) => plansSortWeight[a.plan] - plansSortWeight[b.plan]).filter((p) => p.plan !== 'IO') || [];
    });

    onBeforeMount(async () => {
      getDepositsData().then((res) => {
        depositsData.value = res?.data?.data;
        isStatsLoading.value = false;
      });

      getPortfolioData().then((res) => {
        portfolioData.value = res;
      });
    });

    return {
      isStatsLoading,
      currentTab,
      tabs,
      stats,
      portfolio,
    };
  },
};
</script>

<style scoped lang="scss">
.sales-tabs {
  padding-left: 40px;

  :deep(.info) {
    margin-top: 30px;
  }

  :deep(.portfolio) {
    margin-top: 30px;
  }
}

.table-wrap {
  margin-top: 30px;
}
</style>
