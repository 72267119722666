<template>
  <div class="portfolio">
    <PortfolioInfoItem v-for="(item, i) in portfolio" :key="i" :data="item" />
  </div>
</template>

<script>
import PortfolioInfoItem from './PortfolioInfoItem.vue';

export default {
  name: 'PortfolioInfo',
  components: {
    PortfolioInfoItem,
  },
  props: {
    portfolio: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style scoped lang="scss">
.portfolio {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
</style>
