import { reactive, ref } from 'vue';

import { TABLE_TYPES, rowSelectionCellOptions } from '@/common/tableTypes';

export const columns = [
  {
    ...rowSelectionCellOptions,
  },
  {
    name: 'createdAt',
    type: TABLE_TYPES.DATE_TIME,
    isShow: true,
    width: 300,
  },
  {
    name: 'uid10Digits',
    isShow: true,
    width: 400,
    isPermanent: true,
    type: TABLE_TYPES.TEXT_COPY,
  },
  {
    name: 'uid',
    isShow: true,
    isPermanent: true,
    type: TABLE_TYPES.TEXT_COPY,
  },
  {
    name: 'companyName',
    isShow: true,
    width: 300,
    type: TABLE_TYPES.TEXT_COPY,
  },
  {
    name: 'plan',
    type: TABLE_TYPES.PLAN,
    isShow: true,
    isPermanent: true,
  },
  {
    name: 'cycle',
    type: TABLE_TYPES.CYCLE,
    isShow: true,
    isPermanent: true,
  },
  {
    name: 'amount',
    type: TABLE_TYPES.CURRENCY,
    isShow: true,
    isPermanent: true,
  },
  // {
  //   name: '',
  //   width: 58,
  //   minWidth: 58,
  //   isShow: true,
  //   type: 'Custom',
  //   nonSelectable: true,
  //   render: actionCellRenderer,
  // },
];

export const filters = reactive([
  {
    label: 'Date Range',
    type: 'DATE_RANGE',
    field: ['dateFrom', 'dateTo'],
    modelValue: ref([]),
  },
]);
