<template>
  <div class="item">
    <div class="d-flex align-items-center" style="gap: 20px;">
      <AppIcon :name="data.icon" size="32px" />
      <AppText class="font-medium">
        {{ data.plan }}
      </AppText>
    </div>
    <div class="d-flex" style="gap: 60px;">
      <div v-if="data.plan !== 'PRO'">
        <AppText variant="div" :opacity="0.4">
          Monthly
        </AppText>
        <AppText variant="div" size="20px" class="font-medium" mt="6px">
          {{ data.monthlyTotal || 0 }}
        </AppText>
      </div>
      <div v-if="data.plan !== 'FREE'">
        <AppText variant="div" :opacity="0.4">
          Yearly
        </AppText>
        <AppText variant="div" size="20px" class="font-medium" mt="6px">
          {{ data.yearlyTotal || 0 }}
        </AppText>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'PortfolioInfoItem',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const minWidth = computed(() => (['FREE', 'PRO'].includes(props.data.plan) ? '200px' : '260px'));

    return { minWidth };
  },
};
</script>

<style scoped lang="scss">
.item {
  background: var(--color-F7F7F7);
  height: 160px;
  padding: 20px;
  border-radius: 8px;
  min-width: v-bind('minWidth');
  display: flex;
  flex-direction: column;
  gap: 40px;
}
</style>
