<template>
  <div class="item">
    <AppText size="12px" class="font-medium" variant="div" mb="12px">
      {{ title }}
    </AppText>

    <FSkeleton variant="text" :is-loading="isLoading" height="28px" style="margin-bottom: 8px;">
      <CurrencyFormatter
        size="18px"
        class="font-medium"
        :value="amount"
        :format="numberFormat"
        is-account-currency
        mb="8px"
      />
    </FSkeleton>

    <FSkeleton variant="text" :is-loading="isLoading">
      <AppText color="var(--color-black-04)" variant="div">
        {{ depositQty }} deposits
      </AppText>
    </FSkeleton>
  </div>
</template>

<script>
import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';

import { useAdmin } from '@/composables/useAdmin';

export default {
  name: 'DepositsInfoItem',
  components: {
    CurrencyFormatter,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    amount: {
      type: Number,
      default: 0,
    },
    depositQty: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { numberFormat } = useAdmin();
    return { numberFormat };
  },
};
</script>

<style scoped lang="scss">
.item {
  height: 72px;
  min-width: 80px;
}
</style>
