import { useFetch } from '@/api';
import { useProfile } from '@/composables/useProfile';
import { downloadAsFile } from '@/utils/functions';

export const getDepositsData = async () => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: '/admin/deposits/stats',
  });

  return { isSuccess, data };
};

export const getPortfolioData = async () => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: '/admin/deposits/portfolio',
  });

  const transformedArray = [];

  const planOrder = {
    FREE: 1,
    PRO: 2,
    BUSINESS: 3,
    BLACK: 4,
  };

  if (isSuccess) {
    // eslint-disable-next-line no-unused-expressions
    data.forEach((item) => {
      const existingItem = transformedArray.find(({ plan }) => plan === item.plan);

      if (existingItem) {
        if (item.cycle === 'MONTHLY') {
          existingItem.monthlyTotal = item.total;
        } else if (item.cycle === 'YEARLY') {
          existingItem.yearlyTotal = item.total;
        }
      } else {
        const transformedItem = {
          plan: item.plan,
          monthlyTotal: '',
          yearlyTotal: '',
        };

        if (item.cycle === 'MONTHLY') {
          transformedItem.monthlyTotal = item.total;
        } else if (item.cycle === 'YEARLY') {
          transformedItem.yearlyTotal = item.total;
        }

        transformedArray.push({ icon: `plan-${transformedItem?.plan.toLowerCase()}`, ...transformedItem });
      }
    });
  }

  return transformedArray.sort((a, b) => planOrder[a.plan] - planOrder[b.plan]);
};

export const getIncomeStatement = async ({ invoiceId, uid, invoiceName }) => {
  const { axiosWrapper } = useFetch();
  const response = await axiosWrapper({
    type: 'get',
    url: `/admin/subscriptions/plans/invoice/single/${invoiceId}`,
    params: { isFreshResponse: true },
    responseType: 'blob',
  });

  if (response.result?.data) {
    downloadAsFile({
      data: response.result.data,
      filename: `${uid}-${invoiceName}`,
      type: 'pdf',
    });
  }
};

export const downloadInvoices = async ({ invoiceIds }) => {
  const { axiosWrapper } = useFetch();

  const { currentDate } = useProfile();

  const response = await axiosWrapper({
    type: 'get',
    url: '/admin/subscriptions/plans/invoice/bulk',
    params: { invoiceIds },
    responseType: 'blob',
  });

  if (response.result?.data) {
    const filename = `invoices_${currentDate.value.replace(/[/ - :]/g, '_')}`;
    downloadAsFile({
      data: response.result.data,
      filename,
      type: 'zip',
    });
  }
};
