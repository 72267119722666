<template>
  <TransitionWrapper>
    <div style="padding-right: 40px;">
      <FiltersHeader
        :module-name="moduleName"
        :api-path="apiPath"
        :columns="columns"
        :statuses="statuses"
        :filters="filters"
        has-crutch-for-complete-status
      />

      <PaginationTable
        has-no-hover
        :module-name="moduleName"
        :data="processedItems"
        :api-path="apiPath"
        :columns="columns"
      />
    </div>
  </TransitionWrapper>
</template>

<script>
import { computed, onBeforeMount } from 'vue';

import FiltersHeader from '@/components/Modules/DataTable/FiltersHeader.vue';
import PaginationTable from '@/components/Modules/DataTable/PaginationTable.vue';
import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';

import { usePagination } from '@/composables/usePagination';
import { MODULES_NAMES } from '@/common/modules';

import { columns, filters, statuses } from './data';

export default {
  name: 'DepositsTable',
  components: {
    FiltersHeader,
    PaginationTable,
    TransitionWrapper,
  },
  setup() {
    const moduleName = MODULES_NAMES.ADMIN_DEPOSITS;
    const apiPath = '/admin/deposits/list';

    const { getData, items } = usePagination(apiPath, {
      eventName: 'update-sales-page',
      moduleName,
    });

    onBeforeMount(async () => {
      await getData();
    });

    const processedItems = computed(() => items.value.map((item) => ({
      ...item,
      companyName: item.merchant?.companyName,
      uid: item.merchant?.uid,
      exchangeCurrency: item.deposit?.currency,
      value: item.value,
      valueInUsd: item.valueInUsd,
      address: item.deposit?.address,
      status: item.status === 'COMPLETE' ? 'CONFIRMED' : item.status,
    })));

    return {
      moduleName,
      columns,
      apiPath,

      processedItems,
      statuses,
      filters,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.table-grid-wrapper .ag-grid-vue-wrapper) {
  height: calc(100vh - 467px) !important;
}
</style>
