<template>
  <div ref="wrap" class="transition">
    <transition name="fade-absolute-1">
      <slot />
    </transition>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';

export default {
  name: 'SimpleTransition',
  props: {
    fixHeight: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const wrap = ref(null);
    const height = ref('0px');

    const setHeight = () => {
      height.value = props.fixHeight ? `${props.fixHeight}px` : `${wrap?.value?.children[0]?.offsetHeight}px`;
    };

    const observer = new MutationObserver(() => {
      setHeight();
    });

    onMounted(() => {
      setHeight();
      observer.observe(wrap.value, { attributes: true, childList: true, subtree: true });
    });

    return {
      wrap,
      height,
    };
  },
};
</script>

<style scoped lang="scss">
.transition {
  position: relative;
  height: v-bind('height');

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>
