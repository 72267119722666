<template>
  <div>
    <AppText size="17px" class="font-medium" variant="div" mb="20px">
      Invoice History
    </AppText>

    <div class="invoice-table" :class="{ 'page-processing': isDownloadProcessing }">
      <FiltersHeader
        :module-name="moduleName"
        :api-path="apiPath"
        :columns="columns"
        :filters="filters"
      >
        <template #header-append>
          <FButton
            :loading="isDownloadProcessing"
            :disabled="isDisabledButton"
            size="x-small"
            class="download-button"
            @click="onDownload"
          >
            <AppIcon
              :key="isDisabledButton"
              name="cloud-download"
              size="18px"
              :fill="isDisabledButton ? 'var(--color-DDDDDD)' : 'var(--color-white)'"
              :style="isDisabledButton ? 'margin-right: 0' : ''"
            />
            <AppText v-if="selectedRows?.length" class="font-medium" style="padding-left: 8px;">
              ({{ selectedRows?.length }})
            </AppText>
          </FButton>
        </template>
      </FiltersHeader>

      <PaginationTable
        has-no-hover
        :module-name="moduleName"
        :api-path="apiPath"
        :columns="columns"
      />
    </div>
  </div>
</template>

<script>
import { onBeforeMount, computed, ref } from 'vue';

import FiltersHeader from '@/components/Modules/DataTable/FiltersHeader.vue';
import PaginationTable from '@/components/Modules/DataTable/PaginationTable.vue';

import { MODULES_NAMES } from '@/common/modules';
import { usePagination } from '@/composables/usePagination';

import { downloadInvoices, getIncomeStatement } from '../api';

import { columns, filters } from './data';

export default {
  name: 'InvoicesTable',
  components: {
    PaginationTable,
    FiltersHeader,
  },
  setup() {
    const moduleName = MODULES_NAMES.ADMIN_INVOICES;
    const apiPath = '/admin/subscriptions/plans/invoices';

    const { getData, items, selectedRows } = usePagination(apiPath, {
      eventName: 'update-sales-page',
      moduleName,
    });

    onBeforeMount(async () => {
      await getData();
    });

    const isDownloadProcessing = ref(false);
    const onDownload = async () => {
      isDownloadProcessing.value = true;
      const invoiceIds = selectedRows.value.map((el) => el.id);
      if (invoiceIds.length === 1) {
        const selectedInvoice = items.value.find((invoice) => invoice.id === invoiceIds[0]);
        if (selectedInvoice) {
          await getIncomeStatement({
            invoiceId: selectedInvoice.id,
            uid: selectedInvoice.uid,
            invoiceName: selectedInvoice.uid10Digits,
          });
        }
      } else {
        await downloadInvoices({ invoiceIds });
      }
      isDownloadProcessing.value = false;
    };

    const isDisabledButton = computed(() => selectedRows.value?.length === 0);

    return {
      moduleName,
      apiPath,
      columns,
      filters,
      selectedRows,
      isDisabledButton,

      isDownloadProcessing,
      onDownload,
    };
  },
};
</script>

<style scoped lang="scss">
.invoice-table {
  padding-right: 40px;

  :deep(.table-grid-wrapper .ag-grid-vue-wrapper) {
    height: calc(100vh - 549px) !important;
  }
}

.download-button {
    & > :deep(span) {
      margin: 0 -8px 0 -4px;

      :first-child {
        margin-right: 8px;
      }

      :last-child {
        padding-left: 0 !important;
      }
    }

  :deep(.is-disabled) {
    .icon path {
      fill: rgba(0, 0, 0, 0) !important;
    }
  }

  :deep(.is-loading) {
    margin-right: 12px;
  }
}

.page-processing {
  :deep(.table-grid-wrapper) {
    .ag-checkbox-input-wrapper {
      cursor: default;
      pointer-events: none;
    }
  }
}
</style>
