import { reactive, ref } from 'vue';

import { TABLE_TYPES, statusCellOptions } from '@/common/tableTypes';

export const columns = [
  {
    name: 'createdAt',
    type: TABLE_TYPES.DATE_TIME,
    isShow: true,
    width: 190,
  },
  {
    name: 'depositSequentialId',
    isShow: true,
    isPermanent: true,
    type: TABLE_TYPES.TEXT_COPY,
  },
  {
    name: 'companyName',
    isShow: true,
    width: 180,
    type: TABLE_TYPES.TEXT_COPY,
  },
  {
    name: 'uid',
    isShow: true,
    isPermanent: true,
    width: 150,
    type: TABLE_TYPES.TEXT_COPY,
  },
  {
    name: 'exchangeCurrency',
    type: TABLE_TYPES.CRYPTO,
    isShow: true,
    isPermanent: true,
    width: 95,
  },
  {
    name: 'hash',
    isShow: true,
    width: 300,
    type: TABLE_TYPES.TEXT_COPY,
  },
  {
    name: 'address',
    isShow: false,
    type: TABLE_TYPES.TEXT_COPY,
  },
  {
    name: 'value',
    type: TABLE_TYPES.CRYPTO_TEXT,
    isShow: true,
    isPermanent: true,
  },
  {
    name: 'valueInUsd',
    type: TABLE_TYPES.CURRENCY,
    isShow: true,
    isPermanent: true,
    width: 100,
  },
  {
    ...statusCellOptions,
  },
];

export const statuses = [
  { text: 'All', value: 'ALL' },
  { text: 'Complete', value: 'COMPLETE' },
  { text: 'Unconfirmed', value: 'UNCONFIRMED' },
  { text: 'Expired', value: 'EXPIRED' },
];

export const filters = reactive([
  {
    label: 'Date Range',
    type: 'DATE_RANGE',
    field: ['dateFrom', 'dateTo'],
    modelValue: ref([]),
  },
]);
