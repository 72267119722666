export const MODULES_NAMES = {
  ALERT: 'alert',
  LOADING: 'loading',
  TOOLS: 'tools',
  GLOBAL_SEARCH: 'globalSearch',
  EXCHANGE: 'exchange',
  SWAPS: 'swaps',
  MERCHANTS: 'merchants',
  MERCHANT_PROFILE: 'merchantProfile',
  WITHDRAWALS: 'withdrawals',
  DEPOSITS: 'deposits',
  NEWS: 'news',
  BLACKLISTS: 'blacklists',
  GENERAL_SETTINGS: 'generalSettings',
  USERS_SETTINGS: 'usersSettings',
  LOGS: 'logs',
  API: 'api',
  POSTS: 'posts',
  BANK: 'posts',
  MERCHANT_REGISTRATION: 'merchantRegistration',
  MERCHANT_DASHBOARD: 'merchantDashboard',
  MERCHANT_PAYMENTS: 'merchantPayments',
  MERCHANT_CLIENTS: 'merchantClients',
  MERCHANT_SUBSCRIPTIONS: 'merchantSubscriptions',
  MERCHANT_WALLET: 'merchantWallet',
  MERCHANT_SETTINGS: 'merchantSettings',
  adminDashboard: 'adminDashboard',
  ADMIN_SUBSCRIPTIONS: 'adminSubscriptions',
  RULES: 'rules',
  TEMPLATES: 'templates',

  ADMIN_STAFF: 'adminStaff',
  ADMIN_DEPOSITS: 'adminDeposits',
  ADMIN_INVOICES: 'adminInvoices',
  ADMIN_FLUSH: 'adminFlush',
};
